const APP_NAME = 'Ovis Shell';

const CLIENT_NAME = 'Ovis';

const BRAND_COLORS = {
  PRIMARY: '',
  SECONDARY: ''
};

const LOGO = '/assets/logo/OvisLogo.svg';

const CONTACT_EMAIL = 'app-shell@ovistechnologies.com';

const APP_DOMAIN = '';

export const OKTA_DOMAIN =
  process.env.REACT_APP_OKTA_DOMAIN || 'https://dev-29801974.okta.com';

export const CLIENT_ID =
  process.env.REACT_APP_OKTA_CLIENT_ID || '0oa6i9yaoh3MA89K25d7';

export const CALLBACK_PATH = '/login/callback';
const ISSUER = `${OKTA_DOMAIN}/oauth2/default`;

const HOST = window.location.host;
let REDIRECT_URI;
if (HOST[0] === 'l') {
  REDIRECT_URI = `http://${HOST}${CALLBACK_PATH}`;
} else {
  REDIRECT_URI = `https://${HOST}${CALLBACK_PATH}`;
}
const SCOPES = 'openid profile email';

const SYSTEM_MESSAGE = '';

const config = {
  oidc: {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    pkce: true,
    scopes: SCOPES.split(' ')
  },
  api: {
    domain: process.env.REACT_APP_API_URL
  },
  assets: {
    domain: process.env.REACT_APP_ASSETS_URL
  },
  app: {
    appName: APP_NAME,
    appDomain: APP_DOMAIN,
    brandColors: BRAND_COLORS,
    clientName: CLIENT_NAME,
    logo: LOGO,
    systemMessage: SYSTEM_MESSAGE,
    contactEmail: CONTACT_EMAIL
  }
};

export default config;

import React from 'react';
import Page from '../../components/Page';

const Home = () => {
  return (
    <Page title='Welcome'>
      <p>
        The <span style={{ color: '#f89721' }}>Ovis App Shell</span>, is a web
        application template designed to help companies quickly and easily get
        started on building their own custom web application. With the{' '}
        <span style={{ color: '#f89721' }}>Ovis App Shell</span>, you can save
        time and reduce cost by leveraging our pre-designed templates and
        modules, rather than starting from scratch.
      </p>
      <p>
        Our team has worked diligently to create a user-friendly and intuitive
        platform that allows you to easily customize and personalize your web
        application to fit the specific needs and branding of your company. We
        understand that building a web application can be a daunting and
        time-consuming process, which is why we have developed the{' '}
        <span style={{ color: '#f89721' }}>Ovis App Shell</span> to streamline
        this process and get you up and running as efficiently as possible.
      </p>
      <p>
        {' '}
        We are confident that the{' '}
        <span style={{ color: '#f89721' }}>Ovis App Shell</span> will provide
        the foundation you need to successfully bring your web application idea
        to life. We look forward to supporting you every step of the way as you
        build and launch your unique web application.
      </p>
    </Page>
  );
};

export default Home;

import axios from 'axios';
import { NAV_ENDPOINT } from './endpoints';

import {
  NavGroupCreate,
  NavGroupEdit,
  FetchNav,
  ResponseData,
  NavDeptQuery,
  NavDeptContactQuery
} from '../App.d';
import { handleServiceError } from '.';

export const fetchNav: FetchNav = async (): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'GET_NAV', data: {} })
    .then((response) => response.data)
    .catch(handleServiceError);
};
export const fetchNavElements = async (orgId): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'GET_NAV_ORG', data: { orgId } })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const fetchNavGroups: FetchNav = async (): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'GET_NAV_GROUPS', data: {} })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createNavGroupRequest: FetchNav = async (
  data: NavGroupCreate
): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'CREATE_NAV_GROUP', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const postNavGroups: FetchNav = async (
  data: NavGroupEdit
): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'UPDATE_NAV_GROUPS', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const fetchNavLinks: FetchNav = async (): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'GET_NAV_LINKS', data: {} })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const createNavLinkRequest: FetchNav = async (
  data
): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'CREATE_NAV_LINKS', data })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const postNavLink: FetchNav = async (
  data: NavDeptQuery
): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'UPDATE_NAV_LINKS', data })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const fetchNavOrgConfig: FetchNav = async (
  data
): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'GET_NAV_ORG_CONFIGURATION', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const fetchAllNavOrgConfig: FetchNav = async (
  data
): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'GET_ALL_NAV_ORG_MAPPINGS', data })
    .then((response) => response.data)
    .catch(handleServiceError);
};

export const fetchNavDepts: FetchNav = async (
  data: NavDeptQuery
): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'GET_NAV_DEPARTMENTS', data })
    .then((res) => res.data)
    .catch(handleServiceError);
};

export const createDeptContact: FetchNav = async (
  data: NavDeptContactQuery
): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'CREATE_DEPARTMENT_CONTACT', data })
    .then((res) => res.data)
    .catch(handleServiceError);
};

export const deleteDeptContact: FetchNav = async (
  data: NavDeptContactQuery
): Promise<ResponseData> => {
  return axios
    .post(NAV_ENDPOINT, { action: 'DELETE_DEPARTMENT_CONTACT', data })
    .then((res) => res.data)
    .catch(handleServiceError);
};

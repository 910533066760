export enum Routes {
  home = '/',
  technology = '/technology',
  userManagement = '/settings/user_management',
  orgManagement = '/settings/user_management/organization',
  navManagement = '/settings/navigation_management',
  dmManagement = '/settings/document_manager',
  notificationManagement = '/settings/notification_management',
  helpManagement = '/settings/help_management',
  advDashboard = '/dashboard',
  execDashboard = '/executive_dashboard',
  parser = '/settings/parser',
  guidedTourManagement = '/settings/guided_tour',
  featureManagement = '/settings/feature_management',
  documentManager = '/document_manager',
  getDocument = '/document',
  enhancedDirectory = '/directory',
  globalSearch = '/search',
  dwStatus = '/settings/dw_status',
  commentManagement = '/settings/review_comments',
  quicksight = '/settings/quicksight',
  filePickup = '/file_pickup',
  termsManagement = '/settings/terms_of_use_management',
  settings = '/settings',
  biAccess = '/bi_access'
}

export enum UIRoutes {
  editProfile = 'edit-profile',
  profile = 'profile',
  alerts = 'alerts'
}
